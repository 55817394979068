<script lang="ts">
  import type { Locales } from "../i18n";
  import type { FaxUploadResponse, FaxErrorResponse } from "../scripts/api";
  import StageSubmit, {
    type FaxSubmitTranslations,
    type PhoneConfig,
  } from "./FaxUpload/StageSubmit.svelte";
  import StageUpload, {
    type FaxUploadTranslations,
    type UploadConfig,
  } from "./FaxUpload/StageUpload.svelte";

  type Stage =
    | { name: "upload" }
    | { name: "update"; response: FaxUploadResponse };

  export let currentLocale: Locales;
  export let apiUrl: string;
  export let T: {
    upload: Omit<FaxUploadTranslations, "errors">;
    submit: Omit<FaxSubmitTranslations, "errors">;
    errors: Record<FaxErrorResponse["code"], string>;
  };
  export let uploadConfig: UploadConfig;
  export let initialPhoneNumber: string | undefined;
  export let phoneConfig: PhoneConfig;

  let stage: Stage = { name: "upload" };
</script>

{#if stage.name === "upload"}
  <StageUpload
    T={{ ...T.upload, errors: T.errors }}
    {currentLocale}
    {apiUrl}
    config={uploadConfig}
    onUploadSuccess={(res) => (stage = { name: "update", response: res })}
  />
{:else if stage.name === "update"}
  <StageSubmit
    T={{ ...T.submit, errors: T.errors }}
    {currentLocale}
    {apiUrl}
    uploadResponse={stage.response}
    initialNumber={initialPhoneNumber}
    {phoneConfig}
    reset={() => (stage = { name: "upload" })}
  />
{/if}
