<script lang="ts" context="module">
  export type PhoneConfig = Pick<
    intlTelInput.Options,
    //@ts-ignore
    "i18n" | "utilsScript" | "initialCountry" | "onlyCountries"
  >;

  export interface FaxSubmitTranslations {
    processingRequest: string;
    enterPhone: string;
    pricing: {
      title: string;
      lines: Record<LineItem, string>;
    };
    payAndSend: string;
    errors: Record<FaxErrorResponse["code"], string>;
    faxId: string;
  }
</script>

<script lang="ts">
  import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
  import { onMount } from "svelte";
  import {
    updateFax,
    type FaxErrorResponse,
    type FaxUploadResponse,
    type LineItem,
  } from "../../scripts/api";
  import { toMoney } from "../../scripts/money";
  import { Check, Clipboard, Icon } from "svelte-hero-icons";
  import type { Locales } from "../../i18n.js";
  import posthog from "posthog-js";
  import * as Sentry from "@sentry/astro";
  import type { Iti } from "intl-tel-input";

  const VALIDATION_ERRORS = {
    IS_POSSIBLE: 0,
    INVALID_COUNTRY_CODE: 1,
    TOO_SHORT: 2,
    TOO_LONG: 3,
    IS_POSSIBLE_LOCAL_ONLY: 4,
    INVALID_LENGTH: 5,
  };

  export let T: FaxSubmitTranslations;
  export let phoneConfig: PhoneConfig;
  export let uploadResponse: FaxUploadResponse;
  export let initialNumber: string | undefined;
  export let currentLocale: Locales;
  export let apiUrl: string;
  export let reset: () => void;

  let error: FaxErrorResponse | null = null;
  let telInput: HTMLInputElement;
  let disableSubmit = true;
  let processing = false;
  let iti: Iti;
  let copyAction: "copy" | "copied" = "copy";

  $: lines = [
    { item: "base", price: uploadResponse.price.base },
    { item: "additional", price: uploadResponse.price.additional },
    { item: "schedule", price: uploadResponse.price.schedule },
    { item: "total", price: uploadResponse.price.total },
  ] satisfies { item: LineItem; price: number }[];

  onMount(async () => {
    posthog.capture("fax_submit_stage_loaded");

    iti = intlTelInput(telInput, {
      //@ts-ignore
      defaultToFirstCountry: true,
      showFlags: true,
      showSelectedDialCode: true,
      ...phoneConfig,
    });

    if (!!initialNumber) {
      iti.setNumber(initialNumber);
    }

    const validatePhoneInput = () => {
      disableSubmit = !iti.isValidNumber();

      if (!disableSubmit) {
        posthog.capture("phonenumber_valid");
      }

      return !disableSubmit;
    };

    validatePhoneInput();

    telInput.addEventListener("countrychange", () => validatePhoneInput());
    telInput.addEventListener("change", () => validatePhoneInput());
    telInput.addEventListener("keyup", (e) => {
      const canSubmit = validatePhoneInput();
      if (canSubmit && e.key === "Enter") {
        handleSubmit();
      }
    });
  });

  const formatter = Intl.NumberFormat(currentLocale, {
    style: "currency",
    currency: uploadResponse.price.currency,
  });

  function handleSubmit() {
    const number = iti.getNumber();
    const countryData = iti.getSelectedCountryData();

    switch (iti.getValidationError()) {
      case VALIDATION_ERRORS.INVALID_LENGTH:
      case VALIDATION_ERRORS.TOO_SHORT:
      case VALIDATION_ERRORS.TOO_LONG:
      case VALIDATION_ERRORS.INVALID_COUNTRY_CODE:
        posthog.capture("phonenumber_invalid");
        Sentry.captureMessage("invalid phone number", {
          extra: { number, countryData },
        });
        // TODO: error
        return;

      case VALIDATION_ERRORS.IS_POSSIBLE:
        posthog.capture("phonenumber_possible");
        break;
    }

    error = null;

    disableSubmit = true;
    processing = true;

    updateFax(
      apiUrl,
      uploadResponse.id,
      {
        phone: number,
        phone_country: countryData.iso2!,
        price_signature: uploadResponse.price_signature,
      },
      (response) => {
        if (response.success) {
          posthog.capture("fax_submitted");
          setTimeout(() => (window.location = response.data.checkout_url), 20);
        } else {
          error = response.err;
          disableSubmit = false;
          processing = false;
          Sentry.captureException(response.err);
        }
      },
    );
  }

  function handleCopyFaxId() {
    navigator.clipboard.writeText(uploadResponse.id).then(() => {
      copyAction = "copied";
      setTimeout(() => (copyAction = "copy"), 3000);
    });
  }
</script>

<div class="items-center flex flex-col max-w-xs justify-center m-auto">
  <div
    class="relative rounded-md border border-gray-200 md:w-[500px] bg-gray-50"
  >
    {#if processing}
      <div
        class="absolute bg-white bg-opacity-90 z-10 h-full w-full flex items-center justify-center"
      >
        <div class="flex flex-col items-center gap-y-4">
          <span class="sr-only">Loading</span>
          <svg
            aria-hidden="true"
            class="inline w-12 h-12 text-gray-200 animate-spin fill-green-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="text-xl font-semibold">{T.processingRequest}</span>
        </div>
      </div>
    {/if}

    <div class="px-2 md:px-8 py-4">
      <h2 class="text-2xl font-bold mb-2">
        {T.enterPhone}
      </h2>
      <div class="my-6 flex flex-col gap-y-6 items-center">
        <input
          type="tel"
          class="px-4 py-2 border border-gray-200 rounded-md focus:outline-none"
          bind:this={telInput}
          on:click
          on:blur
          required
        />
        <div class="w-full">
          <div class="px-2 py-2 rounded-sm">
            <h3 class="font-semibold text-center underline mb-5 text-sm">
              {T.pricing.title}
            </h3>

            <div
              class="mt-5 flex flex-col rounded-md border border-gray-200 text-xs"
            >
              {#each lines as line}
                {#if line.price > 0}
                  <div
                    class={`flex items-center justify-between w-full px-4 py-3 ${line.item === "total" ? "bg-gray-100 font-bold" : "border-b border-gray-200"}`}
                  >
                    <span>{T.pricing.lines[line.item]}</span>
                    <span
                      >{formatter.format(
                        toMoney(uploadResponse.price.currency, line.price),
                      )}</span
                    >
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        </div>
        <button
          class="font-semibold text-white bg-green-700 px-4 py-2 rounded-2xl flex gap-x-2 justify-center items-center disabled:opacity-30"
          on:click|preventDefault={handleSubmit}
          disabled={disableSubmit}
        >
          {T.payAndSend}
        </button>
      </div>

      {#if error !== null}
        <div class="font-bold text-red-500 mt-4 mb-4">
          {T.errors[error.code]}
        </div>
      {/if}

      <div class="flex flex-col justify-center fax-info gap-y-2">
        <div class="font-mono text-sm flex flex-row gap-x-4 justify-center">
          <div>
            {T.faxId}&nbsp;<span>{uploadResponse.id}</span>
          </div>
          {#if copyAction === "copy"}
            <button class="w-4 h-4" on:click|preventDefault={handleCopyFaxId}>
              <Icon src={Clipboard} />
            </button>
          {:else if copyAction === "copied"}
            <button class="w-4 h-4" disabled>
              <Icon src={Check} class="text-green-600" />
            </button>
          {/if}
        </div>
        <div class="text-xs text-center">
          {uploadResponse.name}&nbsp;({uploadResponse.type}) / {uploadResponse.pages}p
        </div>

        <div class="mt-4 border-t border-gray-200 pt-4 pb-2">
          <button
            class="text-xs underline cursor-pointer"
            on:click|preventDefault={reset}>Start Over</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
