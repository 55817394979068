<script lang="ts" context="module">
  export interface UploadConfig {
    inputName: string;
    acceptedFileTypes: string[];
  }

  export interface FaxUploadTranslations {
    callToAction: string;
    status: {
      uploading: string;
      analyzing: string;
    };
    errors: Record<FaxErrorResponse["code"], string>;
  }
</script>

<script lang="ts">
  import { ArrowUpTray, Icon } from "svelte-hero-icons";
  import {
    uploadFax,
    type FaxErrorResponse,
    type FaxUploadProgress,
    type FaxUploadResponse,
  } from "../../scripts/api";
  import type { Locales } from "../../i18n";
  import posthog from "posthog-js";

  export let currentLocale: Locales;
  export let apiUrl: string;
  export let T: FaxUploadTranslations;
  export let config: UploadConfig;
  export let onUploadSuccess: (response: FaxUploadResponse) => void;

  let fileInput: HTMLInputElement;
  let progress: FaxUploadProgress | null = null;
  let error: FaxErrorResponse | null = null;

  function onUploadButtonClicked() {
    error = null;
    progress = null;
    posthog.capture("upload_clicked");
    fileInput.click();
  }

  function onError(err: FaxErrorResponse) {
    progress = null;
    error = err;
  }

  function onFileChanged() {
    uploadFax(
      {
        apiUrl: apiUrl,
        timeout: 10000,
        onRequestError: () =>
          onError({
            code: "unexpected_error",
            message: "failed to issue request",
          }),
      },
      {
        file: { name: fileInput.getAttribute("name")!, data: fileInput.files! },
        language: currentLocale,
      },
      (p) => (progress = p),
      (response) => {
        if (response.success) {
          posthog.capture("fax_uploaded");
          onUploadSuccess(response.data);
        } else {
          onError(response.err);
        }
      },
    );
  }
</script>

<div class="items-center flex flex-col max-w-xs justify-center mx-auto">
  <button
    class="upload-btn font-semibold text-white bg-green-700 px-6 py-4 rounded-2xl flex gap-x-2 justify-center items-center"
    on:click|preventDefault={onUploadButtonClicked}
  >
    {T.callToAction}
    <Icon src={ArrowUpTray} solid class="w-4 h-4 inline-block" />
  </button>

  <input
    type="file"
    name={config.inputName}
    accept={config.acceptedFileTypes.join(",")}
    style="display: none;"
    bind:this={fileInput}
    on:change={onFileChanged}
  />

  {#if progress !== null && error === null}
    <div class="mt-8">
      <div class="text-sm mb-2">
        {#if progress.loaded / progress.total >= 0.8}
          {T.status.analyzing}
        {:else}
          {T.status.uploading}
        {/if}
      </div>
      <progress
        max={progress.total}
        value={progress.loaded}
        class="bg-gray-300 rounded-sm"
      ></progress>
    </div>
  {/if}

  {#if error}
    <div class="font-bold text-red-500 mt-4">
      {T.errors[error.code]}
    </div>
  {/if}
</div>
